import $ from 'jquery';

const visualHelpers = {

  init: function () {

    this.randomImage();
    this.setupVisibleTriggers();
    this.storiesListSearchTags();
    this.storiesListSearchTagsCount();
    this.loadNewsStories();
    this.storiesListShowAdvancedSearch();

    $(window).resize(function () {

    });

  },

  storiesListShowAdvancedSearch: function () {
    $('.demographic-filters-button button').click(function (e) {
      $('.demographic-filters-wrap').slideToggle();
      $(this).parent().addClass('is-hidden');
      e.preventDefault();
    })
  },

  storiesListSearchTags: function() {
    $('.m-stories-list-search .fields .filters').click(function(e) {
      $('.m-stories-list-search .tags').slideToggle();
      e.preventDefault();
    })
  },

  storiesListSearchTagsCount: function() {
    $(".tags input[type=checkbox]").change(function() {
      var checked = $(".tags input[type=checkbox]:checked").length
      $(".fields .filters .count").text(checked)
    });

    $(document).ready(function () {
      var checked = $(".tags input[type=checkbox]:checked").length
      $(".fields .filters .count").text(checked)
    });
  },

  loadNewsStories: function() {

    $(document).ready(function () {
      $(".pagination").hide()
    });

    $(".m-load-more button").click(function() {
      var button = $(this)
      var nextPage = parseInt($(button).attr("data-next-page"))
      var perPage = $(button).data("per-page")
      var totalPages = $(button).data("total-pages")
      var path = $(button).data("path")
      var spinner = $(".m-load-more").find(".spinner")
    
      $(button).fadeTo(200, 0, function() {
        $(spinner).fadeIn(200)
      });
      
      $.ajax({
        url: path,
        dataType: 'html',
        data: {
          page: nextPage,
          per_page: perPage,
        },
        success: function(html) {
          $(button).attr("data-next-page", nextPage + 1)
          var div = $('.load-more-items', $(html))
          $('.load-more-container').append(div);
        }, 
        complete: function() {
          setTimeout(function() {
            $(spinner).fadeOut(200, function(){
              if (totalPages > nextPage) {
                $(button).fadeTo(200, 1)
              }
            })
          }, 100);
        }
      })
    })
  },

  setupVisibleTriggers: function () {

    $(document).ready(function () {
      $(".viewable").each(function (i, el) {
        var el = $(el);
        el.addClass("has-viewable-action");
        el.addClass("is-not-viewable");
      });


      setTimeout(function () {
        $(".viewable").each(function (i, el) {
          var el = $(el);
          if (el.visible(true)) {
            el.removeClass("is-not-viewable");
            el.addClass("is-viewable");
          }
        });
      }, 100);

    });

    $(window).scroll(function (event) {

      $(".viewable").each(function (i, el) {
        var el = $(el);
        if (el.visible(true)) {
          el.removeClass("is-not-viewable");
          el.addClass("is-viewable");
        }
      });

    });

  },

  randomImage: function (form) {

    $('.random-image').each(function () {
      if ($(this).css('background-image') == 'none') {
        $(this).css('background-image', 'url(/assets/img/photos/photo-' + Math.floor(Math.random() * 23) + '.jpg)');
      }
    });

  }

};

export default visualHelpers;