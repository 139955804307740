import $ from 'jquery';


const mobileNavigation = {

  init: function () {
    this.setupMobileNavigation();
  },

  setupMobileNavigation: function () {

    /* Superbasic Mobile menu */

    let newNav = $('.m-navigation').clone().insertAfter(".m-navigation");
    $(newNav).removeClass('m-navigation');
    $(newNav).wrap('<div class="m-navigation-mobile"></div>');

    let menuOpen = false;

    $(".hamburger").click(function (e) {
      e.preventDefault();
      if (menuOpen) { // Hide
        $(this).removeClass('is-active');
        menuOpen = false;
        $('.m-navigation-mobile').animate({opacity: 0}, 200, function () {
          $(this).removeClass("is-visible");
          $(this).addClass("is-hidden");
        });
        $("body").removeClass("has-mobile-menu-open");
      }
      else { // Show
        $(this).addClass('is-active');
        menuOpen = true;
        $('.m-navigation-mobile').css('visibility', 'visible');
        $('.m-navigation-mobile').css('z-index', '300');
        $(".m-navigation-mobile").removeClass("is-hidden");
        $(".m-navigation-mobile").addClass("is-visible");
        $('.m-navigation-mobile').animate({opacity: 1}, 200, function () {
        });

        $("body").addClass("has-mobile-menu-open");
      }
    })

  }

};

export default mobileNavigation;