import $ from 'jquery';
import visible from './cms-pages/vendor/jquery-visible';

$.fn.visible = visible;
window.$ = $;

import mobileNavigation from './cms-pages/mobile-navigation';
import visualHelpers from './cms-pages/visual-helpers';


$(document).ready(function() {
  mobileNavigation.init();
  visualHelpers.init();
});